<template>
  <b-card>
    <FormBuilder
      label="Banner"
      create
      :fields="fields"
      :loading-submit="$store.state.banner.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'placeToDisplay',
          label: 'Place',
          type: 'radio',
          notes: 'Place to display the banner.',
          options: [
            {
              text: 'Homepage',
              value: 'home_page',
            },
            {
              text: 'Discover page',
              value: 'discover_page',
            },
          ],
        },
        {
          key: 'countryCode',
          option_key: 'code',
          label: 'Countries',
          type: 'select',
          actionSearch: 'country/searchCodes',
          text: 'text',
        },
        {
          key: 'desktopImages',
          label: 'Image (Desktop)',
          type: 'multiple-image',
          notes: 'Displayed on desktop devices. Please select the image accordingly.',
        },
        {
          key: 'mobileImages',
          label: 'Image (Mobile)',
          type: 'multiple-image',
          notes: 'Displayed on mobile devices. Please select the image accordingly.',
        },
      ],
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('banner/create', {
        ...data,
      })
        .then(callback)
    },
  },
}
</script>

  <style scoped>

  </style>
